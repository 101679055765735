import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Blogs=()=>{
   
    return(
        <div>

<Helmet>
        <title>Trice Technologies Blog: Latest Tech Trends</title>
        <meta name='title' content='Trice Technologies Blog: Latest Tech Trends'/>
        <meta name="description" content="Explore the latest tech trends, insights, and expert advice on the Trice Technologies Blog" />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/Trice-Technologies-Blog-Latest-Tech-Trends" />
        
        <meta property="og:title" content="Trice Technologies Blog: Latest Tech Trends" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/Trice-Technologies-Blog-Latest-Tech-Trends" />
        <meta property="og:image" content="https://tricetechnologies.in/images/blog/blog-banner.webp" />
        <meta property="og:description" content="Explore the latest tech trends, insights, and expert advice on the Trice Technologies Blog" />
      </Helmet>

            <section class="section section-intro context-dark">
            <div class="intro-bg" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow" data-aos='fade-left'>Our Blogs</h1>
                  <p class="intro-description wow" data-aos='fade-right'>Discover expert insights on digital marketing, SEO, and web development to elevate your online presence.</p>
                </div>
              </div>
            </div>
          </section>

          <section>
    
            <div class="section-blog mb-2">
              <div class="container contact">
                {/* <h2>Contact Us</h2> */}
                <p> your go-to source for the latest trends and expert insights in digital marketing, SEO, and web development. Whether you're looking to boost your online presence, optimize your website for search engines, or stay ahead in the ever-evolving world of web development, </p>
              </div>
            </div>
            
            <div class="container">
    <div class="row">
        <div class="blog-banner col-md-12 mb-3 mt-3">
            <img src={`${process.env.PUBLIC_URL}/images/blog/blog-banner.webp`} alt="Digital marketing" width="370" height="307"/>
        </div>
        <div class="col-md-12 mb-3">
            <p>Welcome to our blog, your go-to source for the latest trends and expert insights in digital marketing, SEO, and web development. Whether you're looking to boost your online presence, optimize your website for search engines, or stay ahead in the ever-evolving world of web development, you'll find valuable tips and in-depth articles here. Join us as we explore strategies and share knowledge to help you succeed in the digital landscape.</p>
        </div>
        <div class="col-md-12 each-blog">
            <div class="row">
                <div class="col-md-4 blog-card">
                    <a href="/digital-marketing-blog" class="blog-link">
                        <div class="blog-img">
                            
                        <img src={`${process.env.PUBLIC_URL}/images/home/digital marketing-post.webp`} alt="Digital-marketing" width="370" height="307"/>
                        </div>
                        <p className="pt-3 pl-2">04/07/2024</p>
                        <div class="">
                            <h5>Digital Marketing Isn't Just About Bombarding You With Ads.</h5>
                        </div>
                        <div class="blog-desc">
                            <p style={{color: '#000'}}>The digital age has fundamentally transformed how brands connect with consumers. Gone are the days of passive advertising...</p>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 blog-card mb-5">
                
                    <a href="/ecommerce-seo" class="blog-link">
                        <div class="blog-img">
                        <img src={`${process.env.PUBLIC_URL}/images/home/ecommerce-post.webp`} alt="Ecommerce SEO" width="370" height="307"/>
                        </div>
                        <p className="pt-3 pl-2">12/07/2024</p>
                        <div class="">
                            <h5>Boosting Sales With Effective E-Commerce SEO Strategies</h5>
                        </div>
                        <div class="blog-desc">
                            <p style={{color: '#000'}}>In the fiercely competitive world of e-commerce, standing out from the crowd and driving sales requires more than just having a great product...</p>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 blog-card mb-5">
                    <a href="/ecommerce-blog" class="blog-link">
                        <div class="blog-img">
                        <img src={`${process.env.PUBLIC_URL}/images/home/ecommerce-seo-post.webp`} alt="Ecommerce site" width="370" height="307"/>
                        </div>
                        <p className="pt-3 pl-2">17/07/2024</p>
                        <div class="">
                            <h5>Crafting Successful E-Commerce Sites</h5>
                        </div>
                        <div class="blog-desc">
                            <p style={{color: '#000'}}>The e-commerce landscape is booming! Experts predict global retail e-commerce sales to hit a whopping $6.33 trillion in 2024...</p>
                        </div>
                    </a>
                </div>



                <div class="col-md-4 blog-card mb-5">
                    <a href="/Digital_Marketing_Strategist" class="blog-link">
                        <div class="blog-img">
                        <img src={`${process.env.PUBLIC_URL}/images/blog/strategy.webp`} alt="digital marketing strategist in Palakkad" width="370" height="307"/>
                        </div>
                        <p className="pt-3 pl-2">12/08/2024</p>
                        <div class="">
                            <h5>The Role of a digital marketing strategist: Key to Your Business Success</h5>
                        </div>
                        <div class="blog-desc">
                            <p style={{color: '#000'}}>In today's fast-paced digital world, the success of a business often hinges on its ability to adapt and thrive online. Gone are the days when traditional marketing methods alone could drive growth and visibility...
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


          </section>

         
        </div>
    )
}

export default Blogs;