import React from "react";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";


const Services=()=>{
 
    return(
        <div>

<Helmet>
        <title>Top Web Design & Digital Marketing Services | Palakkad</title>
        <meta name='title' content='Top Web Design & Digital Marketing Services | Palakkad'/>
        <meta name="description" content="Grow online with Trice Technologies! We offer SEO, digital marketing,video,photography, & content creation in Palakkad" />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/services" />
        
        <meta property="og:title" content="top web design & digital marketing services | palakkad" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/services" />
        <meta property="og:image" content="https://tricetechnologies.in/images/services/web development.webp" />
        <meta property="og:description" content="Grow online with Trice Technologies! We offer SEO, digital marketing,video,photography, & content creation in Palakkad" />
      </Helmet>
          
             <section class="section section-intro context-dark">
             <div class="intro-bg" style={{
       backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow" data-aos='fade-left'>Services</h1>
                  <p class="intro-description wow" data-aos='fade-right'>Feel free to learn more about our team and company on this page. We are always happy to help you!</p>
                </div>
              </div>
            </div>
          </section>

          <section class="section section-lg bg-default">
            <div class="container">
              <h3 style={{textAlign: 'center'}} data-aos='fade-right'>LET’S CHECK OUR SERVICES</h3>
              <p style={{textAlign: 'center'}} data-aos='fade-left'>We, at Trice Technologies, are driven by design and customer satisfaction. We don’t only give brands a striking identity; we make them THE BOMB! So, be a BADASS in your business niche and discover the TRUE potential of your business by opting for the following services</p>
              <div class="row row-30">
                <div class="cell-xs-10 col-md-6 col-lg-4 pt-5">
                  <article class="box-minimal">
                    <div class="box-minimal-icon pt-5"><img src="./images/services/web development.webp" data-aos='zoom-in' alt="web-development" /></div>
                    <h4 class="box-minimal-title">Web Development</h4>
                    <div class="box-minimal-text" data-aos='fade-up'>
                      <p> You can make your brand more accessible to clients with web and mobile applications. Besides being the best website design company,
                        our services also include software and application development.</p>
                    </div>
                    <div class="service-btn"><Link to="/website-design-company-in-palakkad/">View</Link>
                </div>
                  </article>
                </div>
                <div class="cell-xs-10 col-md-6 col-lg-4 pt-5">
                  <article class="box-minimal">
                    <div class="box-minimal-icon pt-5"><img src="./images/services/digital marketing.webp" data-aos='zoom-in' alt="digital-marketing"/></div>
                    <h4 class="box-minimal-title">Digital marketing</h4>
                    <div class="box-minimal-text" data-aos='fade-up'>
                      <p>You might be thinking SMM is the do all of online marketing. But the internet is just not limited to social media. 
                        Digital marketing includes advertising on all digital channels.</p>
                    </div>
                    <div class="service-btn"><Link to="/best-digital-marketing-company-in-palakkad/">View</Link>
                </div>
                  </article>
                </div>
                <div class="cell-xs-10 col-md-6 col-lg-4 pt-5">
                  <article class="box-minimal">
                    <div class="box-minimal-icon pt-5"><span><img src="./images/services/SEO.webp" data-aos='zoom-in' alt="seo"/></span></div>
                    <h4 class="box-minimal-title">SEO</h4>
                    <div class="box-minimal-text" data-aos='fade-up'>
                      <p>The best way to bring organic traffic to your website is by ranking it on search engines.
                        Search optimized ranking helps you get regular leads and business to your website. </p>
                    </div>
                    <div class="service-btn"><Link to="/Best-SEO-Services-in-Palakkad">View</Link>
                </div>
                  </article>
                </div>

                <div class="cell-xs-10 col-md-6 col-lg-4 pt-5">
                    <article class="box-minimal">
                      <div class="box-minimal-icon pt-5"><span><img src="./images/services/photo-video-graphy.webp" data-aos='zoom-in' alt="photography-videography"/></span></div>
                      <h4 class="box-minimal-title">Photography & Videography</h4>
                      <div class="box-minimal-text" data-aos='fade-up'>
                        <p>We offer a wide range of services, including e-commerce photography, white background product photography, amazon product photography ensuring your products look their best for online listings and promotions. Located in Palakkad, we are your local experts in product photography,
                           committed to delivering exceptional results that enhance your brand and attract customers. </p>
                      </div>
                      <div class="service-btn"><Link to="/Professional-Photography-&-Videography-Services">View</Link>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4 pt-5">
                    <article class="box-minimal ">
                      <div class="box-minimal-icon pt-5"><span><img src="./images/services/product branding.webp" data-aos='zoom-in' alt="content-creation"/></span></div>
                      <h4 class="box-minimal-title">Content Creation</h4>
                      <div class="box-minimal-text" data-aos='fade-up'>
                        <p>we recognize that compelling content is essential for a successful social media strategy. Our expert team specializes in creating high-quality social media content, including eye-catching posters, engaging videos, and impactful graphics, tailored to your brand's unique voice and audience. Whether you're a startup, a small business, or a large corporation, we provide customized content solutions that drive engagement and foster brand loyalty. </p>
                      </div>
                      <div class="service-btn"><Link to="/Best-Content-Creation-Services-in-palakkad">View</Link>
                  </div>
                    </article>
                  </div>

                {/* <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/custom software.webp"/></span></div>
                      <h4 class="box-minimal-title">Custom Software Development</h4>
                      <div class="box-minimal-text">
                        <p>Tailored software solutions designed to fit your unique business needs. From ideation to execution,
                            we craft custom software that streamlines operations and accelerates growth. </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/product branding.webp"/></span></div>
                      <h4 class="box-minimal-title">Branding</h4>
                      <div class="box-minimal-text">
                        <p>Revolutionize your business with our SaaS solutions. Access powerful software on-demand, scale effortlessly, and stay ahead in today's dynamic market landscape. </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/e commerse.webp"/></span></div>
                      <h4 class="box-minimal-title">Ecommerce Application Development</h4>
                      <div class="box-minimal-text">
                        <p>Do you have big plans to open an online retail business? Well, you need a functional e-commerce website to start </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>


                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/mobile app.webp"/></span></div>
                      <h4 class="box-minimal-title">Mobile Application Development</h4>
                      <div class="box-minimal-text">
                        <p>Specializing in iOS and Android app development, we transform your vision into user-centric mobile solutions that propel your business forward. </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/saas.webp"/></span></div>
                      <h4 class="box-minimal-title">Saas</h4>
                      <div class="box-minimal-text">
                        <p>Revolutionize your business with our SaaS solutions. Access powerful software on-demand,
                            scale effortlessly, and stay ahead in today's dynamic market landscape. </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/domine service.webp"/></span></div>
                      <h4 class="box-minimal-title">Domain Services</h4>
                      <div class="box-minimal-text">
                        <p>Unlock the perfect domain for your online presence. Our domain services offer seamless registration,management, and optimization, ensuring your brand finds the ideal digital identity. </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/business whatsapp.webp"/></span></div>
                      <h4 class="box-minimal-title">Business Whatsapp</h4>
                      <div class="box-minimal-text">
                        <p>                                        Empower your business communication with WhatsApp Business. Enhance customer engagement, streamline support, and drive growth through seamless messaging solutions tailored for your business needs. </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/email service.webp"/></span></div>
                      <h4 class="box-minimal-title">Email Services</h4>
                      <div class="box-minimal-text">
                        <p>                                        Elevate your communication with our email services. Secure, reliable, and customizable solutions
                            tailored to meet your business needs, ensuring seamless connectivity and professional messaging.  </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div>

                  <div class="cell-xs-10 col-md-6 col-lg-4">
                    <article class="box-minimal">
                      <div class="box-minimal-icon"><span><img src="./images/services/hosting.webp"/></span></div>
                      <h4 class="box-minimal-title">Hosting Solutions</h4>
                      <div class="box-minimal-text">
                        <p>                                        Power your online presence with our hosting solutions. Reliable, scalable, and secure hosting services tailored to suit businesses of all sizes,
                            ensuring optimal performance and uptime for your website or application.  </p>
                      </div>
                      <div class="service-btn"><a href="#">View</a>
                  </div>
                    </article>
                  </div> */}
              </div>
            </div>
          </section>
        </div>
    )
}

export default Services;