import { Helmet } from "react-helmet";

const PhotoVideo=()=>{
   
    return(
        <div>

<Helmet>
        <title>Professional Photography & Videography Services</title>
        <meta name='title' content='Professional Photography & Videography Services'/>
        <meta name="description" content="Trice Technology provides high-quality product photography for Amazon, eCommerce & more. Get a quote today!" />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/Professional-Photography-&-Videography-Services" />
        
        <meta property="og:title" content="Professional Photography & Videography Services" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/Professional-Photography-&-Videography-Services" />
        <meta property="og:image" content="https://tricetechnologies.in/images/services/photography-banner.webp" />
        <meta property="og:description" content="Trice Technology provides high-quality product photography for Amazon, eCommerce & more. Get a quote today!" />
      </Helmet>
            <section class="section section-intro context-dark" style={{height: '500px'}}>
            <div class="intro-bg" style={{
backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow fadeInLeft pro-title">Product Photography & Videography</h1>
                  <p class="intro-description wow fadeInRight"></p>
                </div>
              </div>
            </div>
          </section>

         <div class="service-img">
            <div class="container con-img" >
                <img src={`${process.env.PUBLIC_URL}/images/services/photography-banner.webp`} class="serve-img" alt="photogrphy-banner"/>
            </div>
            
         </div>
         <div class="container">
            <div class="service-content">
                <h2>Capture the Essence of Your Products with Professional Excellence</h2>
                <p>At Trice Technologies, we understand that high-quality visuals are crucial for showcasing your products and driving sales. Our expert team specializes in creating stunning product photography and videography that highlights the unique features and benefits of your offerings. Whether you're an e-commerce store, a manufacturer, or a retailer, we provide tailored, budget-friendly visual solutions to meet your needs.</p>
                <p>We offer a wide range of services, including e-commerce photography, white background product photography, amazon product photography ensuring your products look their best for online listings and promotions. Located in Palakkad, we are your local experts in product photography, committed to delivering exceptional results that enhance your brand and attract customers.</p>
             </div>
            
         </div>
         <div class="container" style={{paddingBottom: '2%'}}>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="photography" style={{paddingTop: '3%'}} >
                            <div class="col-md-12">
                                <div class="row icon-img" >
                                    <div class="col-md-1">
                                        <img src={`${process.env.PUBLIC_URL}/images/services/photography.webp`} style={{position: 'absolute',right: '0'}} class="imgs" alt="photography-img"/>
                                    </div>
                                    <div class="col-md-10">
                                        <h3>Product Photography</h3>
                                    </div>
                                </div>
                            </div>
                            
                            <ul>
                                <p class="list-title">Studio Photography</p>
                                <li>
                                    <ul >
                                        <li>Equipped with advanced lighting, backdrops, and props to bring out the best in your products.</li>
                                        <li>Perfect for e-commerce, catalogs, and promotional materials.</li>
                                    </ul>
                                </li>
                                <li class="list-title">Lifestyle Photography</li>
                                <li>
                                    <ul>
                                        <li>Equipped with advanced lighting, backdrops, and props to bring out the best in your products.</li>
                                        <li>Perfect for e-commerce, catalogs, and promotional materials.</li>
                                    </ul>
                                </li>
                            </ul>
                         </div>
                    </div>
                    <div class="row">
                        <div class="photography" style={{paddingTop: '2%'}}>
                            <div class="col-md-12">
                                <div class="row icon-img" >
                                    <div class="col-md-1">
                                        <img src={`${process.env.PUBLIC_URL}/images/services/videography.webp`} style={{position: 'absolute',right: '0'}} class="imgs" alt="videography-img"/>
                                    </div>
                                    <div class="col-md-10">
                                        <h3 >Product Videography</h3>
                                    </div>
                                </div>
                            </div>
                            
                            <ul>
                                <li class="list-title">Product Demos:</li>
                                <li>
                                    <ul>
                                        <li>Showcase your product's features, benefits, and usage through high-quality demonstration videos.</li>
                                        <li>Essential for e-commerce listings, instructional content, and marketing campaigns.</li>
                                    </ul>
                                </li>
                                <li class="list-title">Promotional Videos</li>
                                <li>
                                    <ul>
                                        <li>Create captivating promotional videos that tell your brand’s story and highlight your products.</li>
                                        <li>Perfect for social media, website, and advertising campaigns.</li>
                                    </ul>
                                </li>
            
                                <li class="list-title">Unboxing Videos</li>
                                <li>
                                    <ul>
                                        <li>Professional unboxing videos that give potential customers a detailed view of what they can expect.</li>
                                        <li>Great for YouTube, social media, and your product pages.</li>
                                    </ul>
                                </li>
                            </ul>
                         </div>
                    </div>
                </div>
                
                        <img src={`${process.env.PUBLIC_URL}/images/services/photo-video.webp`} class="photography-img" alt="photography-videography-img"/>

                </div>
            </div>

            <div class="container">
            
           <div class="row">
            <div class="col-md-4">
                <div class="app-card">
                       
                    <h3 class="app-title">Applications</h3>
                    <p class="app-description">Our product photography and videography services are designed to enhance your brand presence across various platforms and marketing channels</p>
                </div>
                <img src={`${process.env.PUBLIC_URL}/images/services/photo-video.webp`} style={{marginTop: '20px'}} alt="photography-videography-img"/>
            </div>
            <div class="col-md-8">
                <div class="app-list">
                    <ul>
                        <li><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span> Digital Marketing</li>
                    </ul>
                    
                    
                      
                            <p>Enhance your online advertising campaigns with compelling visuals that attract and convert customers.</p>
                        
                        
                    
                </div>
                <div class="app-list">
                    <ul>
                        <li><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span> Social Media</li>
                    </ul>
                    
                    
                        <p>Create engaging and shareable content that boosts your social media presence and audience engagement.</p>
                    
                </div>
                <div class="app-list">
                    <ul>
                        <li><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span> Amazon Listings</li>
                    </ul>
                    
                   
                        <p>Optimize your Amazon product listings with high-quality images and videos that increase visibility and sales.</p>
                    
                    
                </div>
                <div class="app-list">
                    <ul>
                        <li><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span> Brand Posters</li>
                    </ul>
                    
                   
                        <p>Design striking posters that communicate your brand message and product highlights effectively.</p>
                    
                    
                </div>
                <div class="app-list">
                    <ul>
                        <li><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span> Advertisements</li>
                    </ul>
                    
                   
                        <p>Develop eye-catching advertisements for print and online media that capture attention and drive action.</p>
                    
                    
                </div>
                <div class="app-list">
                    <ul>
                        <li><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span> Website Content</li>
                    </ul>
                    
                    
                        <p>Improve your website’s appeal and user experience with professional images and videos.</p>
                    
                    
                </div>
                <div class="app-list">
                   
                    <ul>
                        <li><span> <img src={`${process.env.PUBLIC_URL}/images/arrow.webp`} alt="arrow1"/> </span> Email Marketing</li>
                    </ul>
                    <p>Enrich your email campaigns with visuals that enhance click-through rates and engagement.</p>
                </div>
            </div>
           </div>

                
                  
           
           
         </div>
        </div>
    )
}

export default PhotoVideo;