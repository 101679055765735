import React from 'react';


const Team = () => {
    const teamMembers = [
        { img: 'team1-hover.jfif', hoverImg: 'team1.jpg', name: 'Team Name 1', role: 'Designer' },
        { img: 'team1-hover.jfif', hoverImg: 'team1.jpg', name: 'Team Name 1', role: 'Designer' },
        { img: 'team1-hover.jfif', hoverImg: 'team1.jpg', name: 'Team Name 1', role: 'Designer' },
        { img: 'team1-hover.jfif', hoverImg: 'team1.jpg', name: 'Team Name 1', role: 'Designer' },
    ];

    return (
        <div>
            <section class="section section-intro context-dark" style={{height: '500px',backgrounColor: '#0e0e10'}}>
            <div class="intro-bg" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}></div>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-8 text-center">
                  <h1 class="font-weight-bold wow fadeInLeft web">Our Team</h1>
                  <p class="intro-description wow fadeInRight"></p>
                </div>
              </div>
            </div>
          </section>
             <div class="service-img service-web">
            <div class="container">
                <img src="https://img.freepik.com/premium-photo/portrait-professional-business-team-standing-middle-spacious-office_160672-37445.jpg?w=740" alt="web-banner" class="serve-img" style={{marginLeft :'20%'}}/>
            </div>
            
         </div>
            <div className="container">
                <div className="teams">
                    <h4 className="text-center">Our Team</h4>
                    <div className="row">
                        {teamMembers.map((member, index) => (
                            <div className="col-md-3" key={index}>
                                <div className="team-img">
                                    <img src={`${process.env.PUBLIC_URL}/images/Team/${member.img}`} alt={member.name} className="team-image" />
                                    <img src={`${process.env.PUBLIC_URL}/images/Team/${member.hoverImg}`} alt={member.name} className="team-image-hover" />
                                </div>
                                <div className="team-name">
                                    <h5>{member.name}</h5>
                                </div>
                                <div className="team-role">
                                    <p><b>{member.role}</b></p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Team;
